/* Estilos del DatePicker Popup */
.react-datepicker {
    font-family: "Poppins", sans-serif; /* Establece la fuente del DatePicker */
    border-radius: 8px; /* Bordes redondeados para darle un aspecto más moderno */
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.15); /* Sombra para darle un efecto flotante */
    border: none; /* Elimina cualquier borde */
  }
  
  /* Estilo del encabezado del calendario */
  .react-datepicker__header {
    background-color: #AA8453; /* Color de fondo del encabezado */
    border-bottom: none; /* Elimina el borde inferior del encabezado */
    color: #fff; /* Color del texto en blanco */
    padding: 12px; /* Espaciado interno (padding) en el encabezado */
    border-top-left-radius: 8px; /* Redondeo de la esquina superior izquierda */
    border-top-right-radius: 8px; /* Redondeo de la esquina superior derecha */
    text-transform: capitalize; /* Capitaliza la primera letra del mes */
  }
  
  /* Estilo de los nombres de los días (Lun, Mar, etc.) */
  .react-datepicker__day-name {
    color: #fff; /* Color blanco para los nombres de los días */
    font-size: 14px; /* Tamaño de fuente de 14px */
    margin: 0.3rem; /* Margen entre los nombres de los días */
    text-transform: capitalize; /* Capitaliza los nombres de los días */
  }
  
  /* Estilo de los días del calendario */
  .react-datepicker__day {
    color: #495057; /* Color gris para los días disponibles */
    font-size: 14px; /* Tamaño de fuente de 14px */
    margin: 0.3rem; /* Margen alrededor de los días */
    transition: background-color 0.3s ease, color 0.3s ease; /* Transiciones suaves al hacer hover o seleccionar un día */
  }
  
  /* Estilo para los días seleccionados */
  .react-datepicker__day--selected {
    background-color: #a37d4c; /* Color de fondo del día seleccionado */
    color: #fff; /* Color blanco para el texto del día seleccionado */
    border-radius: 50%; /* Hace que el día seleccionado tenga forma de círculo */
    font-weight: bold; /* Texto en negrita para el día seleccionado */
  }
  
  /* Estilo para los días que están en hover */
  .react-datepicker__day:hover {
    background-color: #967142; /* Cambia el fondo cuando se hace hover sobre un día */
    color: #fff; /* Cambia el color del texto a blanco en hover */
    border-radius: 50%; /* Mantiene el efecto de círculo al hacer hover */
  }
  
  /* Navegación entre meses */
  .react-datepicker__navigation {
    top: 20px; /* Posiciona la navegación un poco más abajo */
    border: none; /* Elimina cualquier borde */
    background-color: transparent; /* Fondo transparente para los botones de navegación */
    color: #a37d4c; /* Cambia el color de los íconos de navegación */
  }
  
  /* Cambiar estilo del ícono de navegación (flechas) */
  .react-datepicker__navigation-icon::before {
    border-color: #fff; /* Cambia el color de las flechas de navegación al tono de acento */
  }
  
  /* Estilo para el mes actual (para destacar visualmente) */
  .react-datepicker__current-month {
    color: #fff; /* Color blanco para el mes actual */
    font-weight: bold; /* Negrita para resaltar el mes actual */
    letter-spacing: 0.05em; /* Espaciado entre letras para mejorar la legibilidad */
    font-size: 16px; /* Tamaño de fuente del mes actual */
    text-transform: capitalize; /* Capitaliza el nombre del mes */
  }
  
  /* Estilo para deshabilitar los días anteriores a la fecha actual */
  .react-datepicker__day--disabled {
    color: #d3d3d3; /* Color gris claro para los días deshabilitados */
    pointer-events: none; /* Deshabilita la interacción con los días anteriores */
    background-color: transparent; /* Fondo transparente para los días deshabilitados */
    opacity: 0.6; /* Reduce la opacidad para que sea visualmente claro que están deshabilitados */
  }
  
  /* Estilo para los días dentro de un rango seleccionado */
.react-datepicker__day--in-range {
    background-color: #a37d4c; /* Color de fondo acorde a tu paleta para días en el rango */
    color: #fff; /* Texto en blanco */
    border-radius: 50%; /* Mantiene el efecto de círculo */
  }
  
  /* Estilo para los días dentro de un rango que se está seleccionando */
  .react-datepicker__day--in-selecting-range {
    background-color: #967142; /* Color de acento para los días que se están seleccionando */
    color: #fff; /* Texto blanco */
    border-radius: 50%; /* Mantiene el círculo */
  }
  
  /* Estilo para el hover en los días dentro del rango */
  .react-datepicker__day--in-range:hover,
  .react-datepicker__day--in-selecting-range:hover {
    background-color: #967142; /* Cambia el color de fondo al acento en hover */
    color: #fff; /* Mantiene el texto en blanco */
    border-radius: 50%; /* Mantiene el círculo */
  }
